<template>
    <div class="wrapper">
        <div id="meet">
        </div>
    </div>
</template>

<script>
export default {
    name: "Conference",
    data() {
        return {
            user: {},
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'))
        const domain = 'vks.birgeoqy.kz';
        const options = {
            roomName: 'schedule/' + this.$route.params.room,
            width: 700,
            height: 500,
            userInfo: {
                email: this.user.email,
                displayName: this.user.first_name + ' ' + this.user.last_name
            },
            parentNode: document.querySelector('#meet')
        };
        const api = new JitsiMeetExternalAPI(domain, options);
    }
}
</script>

<style>
iframe{
    max-width: 100%;
}
</style>
